import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 90",
  week: "Semana 13",
  day: "11",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-11",
  path: "/cronologia/semana-13#dia-11-jun/",
};

const Day90 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticadoen{" "}
          <strong>España</strong> 2.016 casos mediante pruebas PCR. En ese mismo
          periodo, 151 personas han requerido hospitalización (7,5% de los
          diagnósticos), de los que 10 han sido ingresos en la UCI, y se han
          producido 32 fallecimientos.
        </ModText>
        <ModText>
          Esta semana el Gobierno ha aprobado un paquete de medidas
          extraordinarias dotado con 90,5 millones de euros para paliar los
          efectos de COVID-19 en el <strong>sector del vino</strong>. El texto
          recoge la destilación de crisis, ayudas al almacenamiento privado y
          cosecha en verde.
        </ModText>
        <ModText>
          También se ha convalidado en el Congreso el{" "}
          <InlineLink link="https://boe.es/boe/dias/2020/05/27/pdfs/BOE-A-2020-5315.pdf">
            Real Decreto-Ley
          </InlineLink>{" "}
          de medidas complementarias en materia agraria, científica, económica,
          de empleo y Seguridad Social y tributarias para mitigar el impacto de
          COVID-19.
        </ModText>
        <ModImage
          src="/images/svg/ayudaeconomica-subvencion.svg"
          alt="subvención al vino"
        />

        <ModText>
          Otra de las medidas que ha entrado en vigor hoy y cobra especial
          relevancia en esta crisis sanitaria, es la aprobación de la
          tramitación del <strong>Proyecto de Ley</strong> que deroga el despido
          por baja médica justificada.
        </ModText>
        <ModText>
          A nivel internacioanal, el Instituto Butantan de Brasil, uno de los
          mayores productores de vacunas del país, se ha unido a Sinovac Biotech
          de China, para desarrollar un candidato a la vacuna COVID-19
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day90;
